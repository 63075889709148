import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { supabase } from 'supabaseClient'
import parse from 'html-react-parser'
import moment from 'moment'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ReadMoreIcon from '@mui/icons-material/ReadMore'

import Loading from 'components/loading'

const colorIns = (p, inflections = [], colorTags = false, cap = true) => {
  if (colorTags) {
    p = p.replaceAll('{it}', '<span style="color:#EFAF67;">')
    p = p.replaceAll('{/it}', '</span>')
  }
  p = p.replace(/\{.*?\}/g, '')
  for (const x of inflections) {
    p = p.split(x).join(`$1${x}$2`)
  }
  p = p.replaceAll('$1', '<span style="color:#15B689;">')
  p = p.replaceAll('$2', '</span>')
  return cap ? p.capitalize() : p
}

function Vocabulary () {
  const [vocab, setVocab] = useState(null)
  const [expanded, setExpanded] = useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const getItems = async () => {
    try {
      const { data, error } = await supabase.rpc('vocabulary', { today: moment().format('YYYY-MM-DD') })
      if (error) throw error

      setVocab(data)
      console.log(data)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getItems()
  }, [])

  return (
    <Loading condition={vocab}>
      {vocab ? vocab.map((item, i) => (
        <Card variant="outlined" sx={{ mt: 2, mb: vocab.length === i+1 ? 2 : 0, backgroundColor: '#202020' }} key={item.word}>
          <Accordion disableGutters elevation={0} sx={{ backgroundColor: 'transparent', '&:before': { display: 'none' } }} expanded={expanded === item.word} onChange={handleChange(item.word)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5" component="div" sx={{ fontWeight: 'bold', flexGrow: 1 }}>
                {item.word}
              </Typography>
              <IconButton sx={{ mr: 1 }} component={Link} to={`/search/${item.word}`}><ReadMoreIcon /></IconButton>
              <Box sx={{ width: '100%', height: 2, position: 'absolute', bottom: 0, left: 0, display: 'flex', gap: '2px' }}>
                {[...Array(item.lvl)].map((l, i) => (
                  <Box sx={{ width: '10%', height: 2, backgroundColor: '#15B689' }} key={i}></Box>
                ))}
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ pt: 2 }}>
              {item.examples.map((example, i) => (
                <Box sx={{ pb: i+1 !== item.examples.length ? 1 : 0, color: '#c1c1c1', fontSize: 15, display: 'flex' }} key={i}>
                  <b style={{ color: '#15B689', paddingRight: '5px' }}>-</b>
                  <div>{parse(colorIns(example, item.inflections, true))}</div>
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        </Card>
      )) : ''}
    </Loading>
  )
}

export default Vocabulary
