import { useLocation, Link } from 'react-router-dom'
import './App.css'
import { Outlet } from 'react-router-dom'

import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import CloseIcon from '@mui/icons-material/Close'

import SearchBox from 'components/searchBox'

function App() {
  const location = useLocation()

  return (
    <Box sx={{ height: '100vh' }}>
      <Box sx={{ py: 2, px: 2, display: 'flex', alignItems: 'center', height: 35  }}>
        <MenuBookIcon sx={{ fontSize: 34, pr: 2 }} />
        <Typography variant="h5" component="div" sx={{ fontWeight: 'bold', display: 'flex', flexGrow: 1 }}>
          <Box sx={{ color: '#15B689' }}>Vocab</Box>ulary builder
        </Typography>
        {location.pathname !== '/' ? (
          <IconButton color="primary" component={Link} to="/"><CloseIcon /></IconButton>
        ): ''}
      </Box>
      <Divider sx={{ mx: 2 }} />
      <Box sx={{ height: 'calc(100% - 60px - 68px)', overflowY: 'scroll', px: 2 }}>
        <Outlet />
      </Box>

      <SearchBox />
    </Box>
  )
}

export default App
