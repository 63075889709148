import { useState, useEffect } from 'react'
import { Link, useParams, useLocation } from 'react-router-dom'
import parse from 'html-react-parser'
import moment from 'moment'
import axios from 'axios'
import { supabase } from 'supabaseClient'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Checkbox from '@mui/material/Checkbox'
import FavoriteBorder from '@mui/icons-material/FavoriteBorder'
import Favorite from '@mui/icons-material/Favorite'

import Loading from 'components/loading'

Object.defineProperty(String.prototype, 'capitalize', {
  value: function() {
    return this.charAt(0).toUpperCase() + this.slice(1)
  },
  enumerable: false
})

const colorIns = (p, inflections = [], colorTags = false, cap = true) => {
  if (colorTags) {
    p = p.replaceAll('{it}', '<span style="color:#EFAF67;">')
    p = p.replaceAll('{/it}', '</span>')
  }
  p = p.replace(/\{.*?\}/g, '')
  for (const x of inflections) {
    p = p.split(x).join(`$1${x}$2`)
  }
  p = p.replaceAll('$1', '<span style="color:#15B689;">')
  p = p.replaceAll('$2', '</span>')
  return cap ? p.capitalize() : p
}

const Definition = ({ entry, isFirst, isLast, learnt, setLearnt }) => {
  const hw = entry.hwi ? entry.hwi.hw.replaceAll('*', '') : ''
  let inflections = entry.ins ? entry.ins.map(x => x.if.replaceAll('*', '')) : []
  inflections.push(hw)
  inflections = inflections.concat(inflections.map(x => x.capitalize()))

  const handleLearn = async (ev) => {
    const newLearnt = !ev.target.checked
    if (learnt != newLearnt && hw) {
      try {
        const { data, error } = await supabase
          .from('vocabulary')
          .update({ 'learnt': newLearnt })
          .match({ word: hw })
        if (error) throw error

        setLearnt(newLearnt)
      } catch (error) { console.log(error) }
    }
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="h5" component="div" sx={{ fontSize: 28, fontWeight: 'bold', py: 2 }}>
          {hw}
        </Typography>
        {isFirst ? (
          <Checkbox checked={learnt === null ? false : !learnt} onChange={handleLearn} disabled={learnt === null} icon={<FavoriteBorder />} checkedIcon={<Favorite />} />
        ) : ''}
      </Box>
      <Box>/{entry.hwi ? (entry.hwi.prs ? entry['hwi']['prs'][0]['ipa'] : (entry.hwi.altprs ? entry.hwi.altprs[0]['ipa'] : '')) : ''}/</Box>
      <Box sx={{ pt: 1, pb: 2 }}>
        {entry.ins && entry.ins.map(ins => (
          <Box key={ins.if}><em>{ins.il}</em> <b style={{ paddingLeft: ins.il ? '5px' : 0 }}>{ins.if.replaceAll('*', '')}</b></Box>
        ))}
      </Box>
      <Box sx={{ pb: 1 }}>
        {entry.def && entry.def.length ? entry.def.map((def, i) => (
          <Box key={i}>
            {def.sseq && def.sseq.length && def.sseq.map((sseq, j) => (
              <Box sx={{ pb: 1 }} key={j}>
                {sseq && sseq.length && sseq.map((seq, k) => (
                  <div key={k}>
                    <Box sx={{ pl: /\d/.test(seq[1]['sn']) ? 0 : 1.6, display: 'flex', pb: 1 }}>
                      <Box sx={{ whiteSpace: 'nowrap' }}>
                        <b style={{ color: '#15B689' }}>{seq[1]['sn']}</b>
                      </Box>
                      <Box sx={{ pl: 0.5 }}>
                        {seq[1].sls ? (
                          <em>{seq[1].sls[0]}</em>
                        ) : ''}
                        {seq[1].sgram ? (
                          <span style={{ color: '#c1c1c1' }}>[{seq[1].sgram}]</span>
                        ) : ''} <b style={{ paddingRight: '5px' }}>:</b>
                      {seq[1].dt && seq[1].dt[0][0] === 'text' ? seq[1].dt[0][1].replace(/\|\|.*?\} \{sx\|/g, ', ').replace(/\{sx\|/g, '').replace(/\|\|.*?\}/g, '').replace(/\{.*?\}/g, '') : ''}
                      </Box>
                    </Box>
                    <Box sx={{ pl: 3 }}>
                      {seq[1].dt ? seq[1].dt.map((dt, l) => (
                        <div key={l}>
                          {dt[0] === 'vis' ? dt[1].map((vis, m) => (
                            <Box sx={{ pb: 1, color: '#c1c1c1', fontSize: 15 }} key={m}>
                              <b style={{ color: '#15B689', paddingRight: '5px' }}>-</b>
                              {parse(colorIns(vis['t'], inflections, true))}
                            </Box>
                          )) : ''}
                          {dt[0] === 'uns' ? dt[1].map(uns => uns.map((udt, m) => (
                            <div key={m}>
                              {udt[0] === 'text' ? (
                                <Box sx={{ pb: 1, fontSize: 15 }}>
                                  ( {parse(colorIns(udt[1], inflections, true, false))} )
                                </Box>
                              ) : ''}
                              {udt[0] === 'vis' ? udt[1].map((uvis, n) => (
                                <Box sx={{ pb: 1, color: '#c1c1c1', fontSize: 15 }} key={n}>
                                  <b style={{ color: '#15B689', paddingRight: '5px' }}>-</b>
                                  {parse(colorIns(uvis['t'], inflections, true))}
                                </Box>
                              )) : ''}
                            </div>
                          ))) : ''}
                          {dt[0] === 'wsgram' ? (
                            <Box sx={{ pb: 1, color: '#c1c1c1', fontSize: 15 }}>
                              [{dt[1]}]
                            </Box>
                          ) : ''}
                        </div>
                      )) : ''}
                    </Box>
                  </div>
                ))}
              </Box>
            ))}
          </Box>
        )) : ''}
      </Box>
      {isLast ? '' : <Divider />}
    </Box>
  )
}

function Dictionary () {
  const location = useLocation()
  const params = useParams()
  const [word, setWord] = useState('')
  const [res, setRes] = useState(null)
  const [learnt, setLearnt] = useState(null)

  const saveEntry = async (entry, inflections, examples) => {
    try {
      const { data, error } = await supabase.from('vocabulary').upsert({
        word,
        entry,
        inflections,
        examples,
        lastlookup: moment().format('YYYY-MM-DD HH:mm:ss')
      }, { onConflict: 'word' })
      if (error) throw error

      setLearnt(data[0].learnt)

      console.log('saved')
    } catch (err) {
      console.log(err)
    }
  }

  const search = (entry) => {
    if (!entry) return

    axios.get(`https://www.dictionaryapi.com/api/v3/references/learners/json/${entry}?key=0c139060-1fe6-4d1b-a58a-2dff4dd08cef`)
    .then(res => {
      if (typeof res.data === 'string') setRes([])
      else setRes(res.data)
    }).catch(err => console.log(err))
  }

  useEffect(() => {
    if (res && res.length > 0 && res[0].def) {
      const hw = res[0].hwi ? res[0].hwi.hw.replaceAll('*', '') : ''
      let inflections = res[0].ins ? res[0].ins.map(x => x.if.replaceAll('*', '')) : []
      inflections.push(hw)

      const examples = (res[0].def.map(def =>
        def.sseq ? def.sseq.map(sseq =>
          sseq.length ? sseq.map(seq =>
            seq[1].dt ?
              seq[1].dt.map(dt =>
                dt[0] === 'vis' ? dt[1][0].t :
                (dt[0] === 'uns' ?
                  dt[1].map(uns => uns.map(unsdt =>
                    unsdt[0] === 'vis' ? unsdt[1][0].t : null
                  ))
                : null)
              ) : null
          ) : null
        ) : null
      ) : []).flat(10).filter(x => x)

      saveEntry(res, inflections, examples)
      console.log(JSON.stringify(examples, null, 2))
    }
  }, [res])

  useEffect(() => {
    setWord(params.word)
    search(params.word)
  }, [location])

  return (
    <Loading condition={res}>
      {res && res.length ? (
        typeof res[0] === 'string' ? (
          <Box>
            <Typography variant="h4" component="div" sx={{ fontWeight: 'bold', py: 2 }}>
              {`"${word}"`}
            </Typography>
            <Typography variant="h5" component="div" sx={{ fontSize: 16, fontWeight: 'bold' }}>
              <span>Did you mean: </span>
              {res.map((item, i) => (
                <span key={item}>
                  <Link to={`/search/${item}`} className="app-color">{item}</Link>
                  {res.length === i+1 ? '' : ', '}
                </span>
              ))}
            </Typography>

          </Box>
        ) : res.map((entry, i) => (
          <Definition key={entry.meta.id} entry={entry} isFirst={i === 0} isLast={res.length === i+1} learnt={learnt} setLearnt={setLearnt} />
        ))
      ) : (
        <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold', color: '#909090' }}>
          {'Da heck are you looking for? (´･_･`)'}
        </Box>
      )}
    </Loading>
  )
}

export default Dictionary
