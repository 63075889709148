import { useState, useRef, useEffect } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'

import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import TextField from '@mui/material/TextField'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'

function SearchBox () {
  const location = useLocation()
  const navigate = useNavigate()
  const params = useParams()
  const [word, setWord] = useState('')

  const search = () => navigate(`/search/${word}`)

  const keyPress = (ev, entry) => {
    if (ev.key !== 'Enter' || (params && params.word === word) || word === '') return
    search()
  }

  useEffect(() => {
    if (params.word) {
      if (params.word !== word) setWord(params.word)
    } else setWord('')
  }, [location])

  return (
    <Box sx={{ height: 60, width: '100vw', px: 2, backgroundColor: '#262626', display: 'flex', boxSizing: 'border-box', alignItems: 'center', position: 'fixed', left: 0, bottom: 0 }}>
      <TextField label="Search" size="small" value={word} onChange={(ev) => setWord(ev.target.value.toLowerCase())} onKeyDown={ev => keyPress(ev, word)} sx={{ flexGrow: 1 }} autoComplete="off" autoFocus />
      <Button
        sx={{ ml: 1 }}
        disabled={(params && params.word === word) || word === ''}
        onClick={() => search()}
      ><SearchIcon /></Button>
    </Box>
  )
}

export default SearchBox
