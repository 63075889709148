import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import './index.css'
import App from './App'
import Vocabulary from 'components/vocabulary'
import Dictionary from 'components/dictionary'

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#15B689'
    }
  }
})

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />}>
            <Route path="" element={<Vocabulary />} />
            <Route path="/search/:word" element={<Dictionary />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
