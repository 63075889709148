import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

function Loading ({ condition, children }) {
  return condition ? children : (
    <Box sx={{
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <CircularProgress size={30} />
    </Box>
  )
}

export default Loading
